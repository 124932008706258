import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage: FunctionComponent = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        'warmtepomp',
        'airconditioning',
        'luchtverwarming',
        'ventilatie',
        'condensatieketel',
        'sanitair',
        'koelwagen',
        'verhuur',
      ]}
    />
    <ul>
      <li>
        <Link activeClassName="active" to={'/warmtepompen/'}>
          Warmtepompen
        </Link>
      </li>
      <li>Airconditioning</li>
      <li>Luchtverwarming</li>
      <li>Ventilatie</li>
      <li>Condensatieketels</li>
      <li>Sanitair</li>
      <li>Verhuur van koelwagens</li>
    </ul>
  </Layout>
);

export default IndexPage;
